import { debounce } from 'lodash'
import { getScrollBarWidth } from '../utils/detect-scrollbar.js'

const trackingApp = document.getElementById('quickpac-tracking-app')
let buttonsTransplanted = false

function transplantButtons() {
    const buttons = document.getElementById('trackingAppButtonSource')
    const target = document.getElementById('trackingFooter')

    if (buttons && target && !buttonsTransplanted) {
        target.innerHTML = buttons.innerHTML
        buttonsTransplanted = true
    }
}

trackingApp.addEventListener('onTrackingOpen', () => {
    const scrollBarWidth = getScrollBarWidth();
    if (scrollBarWidth) {
        document.body.style.marginRight = `${scrollBarWidth}px`
    }
    document.body.classList.add('modal-open')
});

// todo - Fix 'DOMSubtreeModified' is deprecated. Consider using 'MutationObserver' instead.
trackingApp.addEventListener('DOMSubtreeModified', debounce(() => {
    const modal = document.querySelector('.tr-modal')
    if (modal.offsetParent) {
        transplantButtons()

        const scrollBarWidth = getScrollBarWidth();
        if (scrollBarWidth) {
            document.body.style.marginRight = `${scrollBarWidth}px`
        }
        document.body.classList.add('modal-open')
    }
}, 150))

trackingApp.addEventListener('onTrackingClosed', () => {
    document.body.style.marginRight = ''
    document.body.classList.remove('modal-open')
    buttonsTransplanted = false
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}
